<template>
  <v-dialog v-model="dialog" width="500px">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <template v-if="isMaster">
          <v-textarea
            v-model="editValue"
            :label="label"
            outlined
            dense
          ></v-textarea>
        </template>
        <template v-else>
          <v-text-field
            v-model="editValue"
            :label="label"
            outlined
            dense
          ></v-text-field>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="emit('update:dialog', false)">Cancel</v-btn>
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

interface Props {
  dialog: boolean;
  title: string;
  label: string;
  value: string;
  isMaster: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:dialog", "save"]);

const editValue = ref(props.value);

watch(
  () => props.dialog,
  (newVal) => {
    if (newVal) {
      editValue.value = props.value;
    }
  },
);

const save = () => {
  emit("save", editValue.value);
  emit("update:dialog", false);
};
</script>
