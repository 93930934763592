<template>
  <v-card class="pa-3 shipper-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Shipper address</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="detail.shipperAddress.name"
          label="Name"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.shipperAddress.address"
          label="Address"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.shipperAddress.additionalAddress"
          label="Add. addr."
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.exportShipperAddress.senderEmail"
          label="Email"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.exportShipperAddress.senderPhone"
          label="Phone Number"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.exportShipperAddress.eoriNumber"
          label="EORI Number"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="detail.shipperAddress.zipcode"
          label="Zipcode"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.shipperAddress.city"
          label="City"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="detail.shipperAddress.country"
          :items="dataStore.originCountries"
          item-text="description"
          item-value="code"
          hide-details
          dense
          label="Country"
          outlined
          ><template slot="selection" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template></v-select
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}

const dataStore = useDataStore();

const props = defineProps<IProps>();
const dateModel = ref<string | null>(null);
const placeHolderModel = ref("");
</script>
