<template>
  <v-card class="pa-3 references-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>References</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.mawbNumber"
          label="MAWB"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.hawbNumber"
          label="HAWB"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.warehouseLocation"
          label="Wareh. Location"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.references.origin"
          :items="dataStore.airports"
          item-text="value"
          item-value="key"
          hide-details
          dense
          label="Origin"
          outlined
        ></v-select>
      </v-col>
      <!-- TODO CHECK -->
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.atbNummerPosition"
          label="Road ATB position"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.atb"
          label="Road ATB"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="invoiceNumbers"
          label="Invoice numbers"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary" @click="openDialog()">Invoice details</v-btn>
      </v-col>
      <v-dialog v-model="invoiceDetailsDialog" width="1200">
        <ShipmentInvoiceDetailsDialog
          :key="timeStampKey"
          :detail="detail"
          :open-dialog.sync="invoiceDetailsDialog"
        ></ShipmentInvoiceDetailsDialog>
      </v-dialog>

      <v-col cols="6">
        <v-select
          v-model="detail.placeholder.connectedSchemeId"
          :items="dataStore.customsSchemes"
          item-text="value"
          item-value="key"
          hide-details
          dense
          label="Connected scheme"
          outlined
        ></v-select>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useDataStore } from "@/stores/data-store";
import { ShipmentDetailViewModel } from "@/openapi";
import { getInvoiceNumbers } from "@/helpers/shipmentDetailHelper";
import ShipmentInvoiceDetailsDialog from "./dialogs/ShipmentInvoiceDetailsDialog.vue";

const dataStore = useDataStore();

interface IProps {
  detail: ShipmentDetailViewModel;
}
const props = defineProps<IProps>();

const timeStampKey = ref(Date.now());
const invoiceDetailsDialog = ref(false);

const openDialog = () => {
  timeStampKey.value = Date.now();
  invoiceDetailsDialog.value = true;
};

const invoiceNumbers = computed(() => {
  return getInvoiceNumbers(props.detail.invoices);
});
</script>
