<template>
  <v-card class="pa-3">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Milestones</v-card-title>
      <v-spacer></v-spacer>
      <v-col cols="3"
        ><v-select
          :items="dataStore.milestoneCustomsHandling"
          v-model="selectedMilestone"
          item-text="milestoneName"
          item-value="milestoneId"
          hide-details
          dense
          label="Milestone"
          outlined
        ></v-select
      ></v-col>

      <v-btn icon @click="addMilestone()" :disabled="!selectedMilestone">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-dialog v-model="newMilestoneOpenDialog" width="660px">
        <ShipmentNewMilestoneDialog
          :key="timeStampKey"
          :open-dialog.sync="newMilestoneOpenDialog"
          @save="commentAdded"
        ></ShipmentNewMilestoneDialog>
      </v-dialog>
    </v-toolbar>
    <v-data-table
      :headers="milestoneHeaders"
      :items="items"
      :items-per-page="10"
      :options.sync="options"
      item-key="id"
      class="elevation-1 pt-5"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.milestoneName }}</td>
          <td>{{ formatDate(item.dateTime) }}</td>
          <td>
            {{ item.employee }}
          </td>
          <td>{{ item.comment }}</td>
          <td>
            {{ item.priority }}
          </td>
          <td>
            <div class="d-flex justify-end">
              <v-btn
                color="error"
                small
                :disabled="item.isDeleting"
                :loading="item.isDeleting"
                @click="onDelete(item)"
                ><v-icon left> mdi-delete </v-icon>Delete</v-btn
              >
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import ShipmentNewMilestoneDialog from "./dialogs/ShipmentNewMilestoneDialog.vue";
import moment from "moment";
import {
  ShipmentDetailApi,
  ShipmentDetailViewModel,
  ShipmentDetailMilestone,
} from "@/openapi";
import { useCrudPage } from "@/composables/crudPage";
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { DataOptions, DataTableHeader } from "vuetify";
import { useDataStore } from "@/stores/data-store";
const api = new ShipmentDetailApi(undefined, "");

interface IProps {
  detail: ShipmentDetailViewModel;
}

export interface EnrichedMilestonesModel extends ShipmentDetailMilestone {
  isDeleting: boolean;
}

const props = defineProps<IProps>();
const dataStore = useDataStore();
const defaultMilestone: ShipmentDetailMilestone = {
  id: 0,
  milestoneName: "",
  milestoneId: 0,
  dateTime: "",
  employee: "",
  employeeId: 0,
  comment: "",
  priority: null,
};
const newMilestone = ref<ShipmentDetailMilestone>({ ...defaultMilestone });

const {
  items,
  disableNewItemButton,
  isLoading,
  addNewItem,
  deleteItem,
  isNewItem,
} = useCrudPage<EnrichedMilestonesModel>(
  { ...defaultMilestone, isDeleting: false },
  "id",
  0,
);

const isDeleting = ref(false);
const selectedMilestone = ref(0);
const timeStampKey = ref(Date.now());
const newMilestoneOpenDialog = ref(false);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const milestoneHeaders = ref<DataTableHeader[]>([
  { text: "Milestone", value: "milestoneName" },
  { text: "Date/time", value: "dateTime" },
  { text: "Employee", value: "employee" },
  { text: "Comment", value: "comment" },
  { text: "Priority", value: "priority" },
  { text: " ", value: "actions", sortable: false, width: "50px" },
]);

const addMilestone = async () => {
  if (selectedMilestone.value) {
    timeStampKey.value = Date.now();
    newMilestone.value = { ...defaultMilestone };
    const milestone = dataStore.milestoneCustomsHandling.find(
      (item) => item.milestoneId == selectedMilestone.value,
    );
    if (!milestone) {
      return;
    }
    newMilestone.value.dateTime = formatDate(new Date());
    newMilestone.value.milestoneId = milestone.milestoneId;
    newMilestone.value.milestoneName = milestone.milestoneName;

    if (milestone?.needComment) {
      newMilestoneOpenDialog.value = true;
      return;
    }
    const response = await api.saveMilestone({
      shipmentDetailId: props.detail.id,
      milestoneId: milestone.milestoneId,
      comment: newMilestone.value.comment,
    });
    if (response.data) {
      items.value.unshift({ ...response.data, isDeleting: false });
      newMilestoneOpenDialog.value = false;
    }
  }
};

const commentAdded = async (remark: string) => {
  const response = await api.saveMilestone({
    shipmentDetailId: props.detail.id,
    milestoneId: newMilestone.value.milestoneId!,
    comment: remark,
  });
  if (response.data) {
    items.value.unshift({ ...response.data, isDeleting: false });
    newMilestoneOpenDialog.value = false;
  }
};

const formatDate = (dateTime: Date | null | undefined) => {
  const dateTimeMoment = moment(dateTime);
  return dateTimeMoment.format("YYYY-MM-DD HH:mm:ss");
};

const loadItems = async () => {
  isLoading.value = true;

  try {
    items.value =
      props.detail.milestones?.map((item) => ({
        ...item,
        isDeleting: false,
      })) ?? [];
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Something went wrong while retrieving the keywords",
    );
  }
  isLoading.value = false;
};

const onDelete = async (item: EnrichedMilestonesModel) => {
  isDeleting.value = true;
  item.isDeleting = true;

  if (isNewItem(item)) {
    // If it's a new item, simply remove it from the items list
    deleteItem(item);
    item.isDeleting = false;
    isDeleting.value = false;
    disableNewItemButton.value = false;
    return;
  }

  // Mock the delete operation
  await deleteCustomsKeyword(item);

  item.isDeleting = false;
  isDeleting.value = false;
};

const deleteCustomsKeyword = async (item: EnrichedMilestonesModel) => {
  try {
    await api.deleteMilestone(item.id!);
    items.value = items.value.filter((i) => i.id !== item.id);
    emitSuccess("Successfully deleted the keyword");
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Something went wrong while deleting the keyword",
    );
  }
};

onBeforeMount(async () => {
  await loadItems();
});
</script>
