<template>
  <v-card class="pa-3 shipper-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Customs Information</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="detail.customsInformation.manifestNumbers"
          label="Manifest Number(s)"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="10">
        <v-text-field
          v-model="exportMrnsDisplay"
          label="Export MRN"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="d-flex align-center">
        <v-btn color="primary" @click="openExportMrnsDialog">Edit</v-btn>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.customsInformation.transitMrn"
          label="Transit MRN"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.customsInformation.dakosyReference"
          label="Dakosy Reference(T1/T2)"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.customsInformation.registrationNumberTrailer"
          label="Registration Number Trailer"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.customsInformation.registrationNumberTruck"
          label="Registration Number Truck"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.customsInformation.seal"
          label="Seal"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.customsInformation.finalLtsNumber"
          label="Final LTS Number"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.customsInformation.routeId"
          :items="dataStore.routes"
          item-text="value"
          item-value="key"
          label="Route"
          hide-details
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.customsInformation.tempWabNumber"
          label="Temp LTS Number"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12"
        ><DatePicker
          v-model="detail.customsInformation.transitMrnIssueDate"
          label="Transit MRN Issue Date"
          format-with="."
          readonly
        ></DatePicker>
      </v-col>
    </v-row>
    <ShipmentExportDialog
      :dialog="exportMrnsDialog"
      title="Edit Export MRNs"
      label="Export MRNs"
      :value="exportMrnsEdit"
      :isMaster="detail.customsInformation.isMaster"
      @update:dialog="exportMrnsDialog = $event"
      @save="saveExportMrns"
    ></ShipmentExportDialog>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { DataApi, ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";
import ShipmentExportDialog from "./dialogs/ShipmentExportDialog.vue";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const dataStore = useDataStore();

const props = defineProps<IProps>();
const dateModel = ref<string | null>(null);
const placeHolderModel = ref("");

const exportMrnsDialog = ref(false);

const exportMrnsEdit = ref("");

const exportMrnsDisplay = computed(() =>
  (props.detail.customsInformation.exportMrns ?? []).join(", "),
);

const openExportMrnsDialog = () => {
  exportMrnsEdit.value = (
    props.detail.customsInformation.exportMrns ?? []
  ).join("\n");
  exportMrnsDialog.value = true;
};

const saveExportMrns = (newValue: string) => {
  props.detail.customsInformation.exportMrns = newValue.split("\n");
};
</script>
