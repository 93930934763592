<template>
  <v-card class="pa-3 shipment-info-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Shipment information</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="invoiceNumberDisplay"
          label="Invoice Number"
          outlined
          dense
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-btn color="primary" class="ml-3" @click="openInvoiceNumberDialog"
        >Edit</v-btn
      >
      <ShipmentExportDialog
        :dialog="invoiceNumberDialog"
        title="Edit Invoice Number"
        label="Invoice Number"
        :value="invoiceNumberEdit"
        :isMaster="detail.customsInformation.isMaster"
        @update:dialog="invoiceNumberDialog = $event"
        @save="saveInvoiceNumber"
      ></ShipmentExportDialog>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-text-field
          v-model="detail.shipmentInformation.pieces"
          label="Pieces"
          outlined
          type="number"
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="detail.shipmentInformation.value"
          step="0.01"
          type="number"
          label="Value"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-select
          v-model="detail.shipmentInformation.currency"
          :items="dataStore.currencies"
          item-text="name"
          item-value="name"
          hide-details
          dense
          label="Currency"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.exportShipmentInfo.reasonForExport"
          label="Reason for export"
          hide-details
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.exportShipmentInfo.shipmentLoaded"
          :items="[
            { text: 'Yes', value: true },
            { text: 'No', value: false },
          ]"
          item-text="text"
          item-value="value"
          hide-details
          dense
          label="Shipment Loaded"
          outlined
          readonly
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.shipmentInformation.realWeight"
          label="Real weight"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.shipmentInformation.incoterms"
          :items="dataStore.incoterms"
          item-text="name"
          item-value="id"
          hide-details
          dense
          label="Incoterms"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.shipmentInformation.volumeWeight"
          label="Volume weight"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.exportShipmentInfo.countryOfOrigin"
          :items="dataStore.originCountries"
          :item-text="formatCountry"
          item-value="code"
          hide-details
          dense
          label="Country of Origin"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <!-- TODO CHECK -->
        <v-text-field
          v-model="detail.shipmentInformation.calculatedVolumeWeight"
          hide-details
          dense
          label="Calc. vol. weight"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          class="ml-3"
          @click="shipmentDimensionsDialog = true"
          >D</v-btn
        >
      </v-col>
      <v-dialog v-model="shipmentDimensionsDialog" width="660px"
        ><ShipmentInfoDimensionsDialog
          :detail="detail"
          :open-dialog.sync="shipmentDimensionsDialog"
        ></ShipmentInfoDimensionsDialog>
      </v-dialog>
      <v-col cols="6">
        <v-btn color="primary" @click="openParcels()">Parcels</v-btn>
      </v-col>
      <v-dialog v-model="parcelsDialog" width="1200px">
        <ShipmentInfoParcelsDialog
          :key="timeStampKey"
          :detail.sync="detail"
          :save="detail"
          :open-dialog.sync="parcelsDialog"
        ></ShipmentInfoParcelsDialog>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { useDataStore } from "@/stores/data-store";
import ShipmentInfoDimensionsDialog from "./dialogs/ShipmentInfoDimensionsDialog.vue";
import ShipmentInfoParcelsDialog from "./dialogs/ShipmentInfoParcelsDialog.vue";
import { ShipmentDetailViewModel } from "@/openapi";
import ShipmentExportDialog from "./dialogs/ShipmentExportDialog.vue";

const dataStore = useDataStore();
interface IProps {
  detail: ShipmentDetailViewModel;
}

const props = defineProps<IProps>();

const invoiceNumberDialog = ref(false);

const invoiceNumberEdit = ref("");

const invoiceNumberDisplay = computed(() =>
  (props.detail.exportShipmentInfo.invoiceNumber ?? []).join(", "),
);

const openInvoiceNumberDialog = () => {
  invoiceNumberEdit.value = (
    props.detail.exportShipmentInfo.invoiceNumber ?? []
  ).join("\n");
  invoiceNumberDialog.value = true;
};

const saveInvoiceNumber = (newValue: string) => {
  props.detail.exportShipmentInfo.invoiceNumber = newValue.split("\n");
};

const timeStampKey = ref(Date.now());
const shipmentDimensionsDialog = ref<boolean>(false);
const parcelsDialog = ref<boolean>(false);

const openParcels = () => {
  timeStampKey.value = Date.now();
  parcelsDialog.value = true;
};

interface Country {
  code: string;
  description: string;
}

const formatCountry = (item: Country) => `${item.code} - ${item.description}`;
</script>
