<template>
  <v-card class="pa-3">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Articles</v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openNewAcArticleDialog">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-simple-table class="pt-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Artikel</th>
            <th>WTN</th>
            <th>Zusatzcode</th>
            <th>Warenbeschreibung</th>
            <th>RC tarif</th>
            <th>SC tarif</th>
            <th>Eigenmasse</th>
            <th>Rohmasse</th>
            <th>Rechnungspreis</th>
            <th>Rechnungswährung</th>
            <th>Artikelpreis (EUR)</th>
            <th>Frachtkosten</th>
            <th>Frachtkostenwährung</th>
            <th>Zollwert (EUR)</th>
            <th>Stat. Wert</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(article, index) in detail.acArticles"
            :key="`${timeAcArticlesStampKey}-${index}`"
          >
            <td>{{ article.article }}</td>
            <td>{{ article.wtn }}</td>
            <td>{{ article.additionalCode }}</td>
            <td>{{ article.goodsDescription }}</td>
            <td>{{ article.rcTariff }}</td>
            <td>{{ article.scTariff }}</td>
            <td>{{ article.netMass?.toFixed(1) }}</td>
            <td>{{ article.rawMass?.toFixed(1) }}</td>
            <td>{{ article.invoicePrice?.toFixed(2) }}</td>
            <td>{{ article.billingCurrency }}</td>
            <td>{{ article.itemPriceEur?.toFixed(2) }}</td>
            <td>{{ article.freightCharges?.toFixed(2) }}</td>
            <td>{{ article.freightCostCurrency }}</td>
            <td>{{ article.customsValueEur?.toFixed(2) }}</td>
            <td>{{ article.statAmount?.toFixed(2) }}</td>
            <td>
              <v-btn icon @click="openAcArticleDialog(article, index)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="deleteAcArticleDialog(article.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <v-dialog v-model="acArticleDialog" width="660px">
          <ShipmentAcArticlesDialog
            :article="selectedAcArticle"
            :index="selectedIndex >= 0 ? selectedIndex : null"
            :saving.sync="savinfAcArticle"
            @close="closeAcArticleDialog"
            @save="saveAcArticle"
          >
          </ShipmentAcArticlesDialog
        ></v-dialog>
        <ConfirmDialog
          v-model="showConfirmDeleteAcArticleDialog"
          color="error"
          @confirm="deleteAcArticle"
          @close="deleteAcArticleId = null"
        >
          <slot name="confirm-delete-text" :default-text="confirmDeleteText">{{
            confirmDeleteText
          }}</slot>
        </ConfirmDialog>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import {
  ShipmentDetailApi,
  ShipmentDetailViewModel,
  ShipmentDetailAcArticleModel,
} from "@/openapi";
import ShipmentAcArticlesDialog from "./dialogs/ShipmentAcArticlesDialog.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue.html";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const api = new ShipmentDetailApi(undefined, "");
const props = defineProps<IProps>();
const timeStampKey = ref(Date.now());
const timeAcArticlesStampKey = ref(Date.now());
const confirmDeleteText = "Are you sure you want to delete this item?";
const savinfAcArticle = ref(false);
const acArticleDialog = ref(false);
const selectedIndex = ref<number | null>(null);
const defaultArticle: ShipmentDetailAcArticleModel = { id: 0 };
const selectedAcArticle = ref<ShipmentDetailAcArticleModel>(defaultArticle);
const deleteAcArticleId = ref<number | null>(null);

const showConfirmDeleteAcArticleDialog = computed({
  get() {
    return deleteAcArticleId.value != null;
  },
  set(value: boolean) {
    deleteAcArticleId.value = null;
  },
});

const openNewAcArticleDialog = () => {
  timeStampKey.value = Date.now();
  selectedIndex.value = null;
  selectedAcArticle.value = { ...defaultArticle };
  acArticleDialog.value = true;
};

const openAcArticleDialog = (
  doc: ShipmentDetailAcArticleModel,
  index: number,
) => {
  timeStampKey.value = Date.now();
  selectedIndex.value = index;
  selectedAcArticle.value = doc;
  acArticleDialog.value = true;
};

const deleteAcArticleDialog = async (id: number) => {
  deleteAcArticleId.value = id;
};

const deleteAcArticle = async () => {
  await api.deleteShipmentDetailAcArticle(deleteAcArticleId.value ?? 0);
  const index = props.detail.acArticles?.findIndex(
    (item) => item.id == deleteAcArticleId.value,
  );
  if (index != undefined && index != null && props.detail.acArticles) {
    props.detail.acArticles.splice(index, 1);
  }
  deleteAcArticleId.value = null;
};

const saveAcArticle = async (
  article: ShipmentDetailAcArticleModel,
  index: number,
) => {
  if (props.detail.acArticles != null && props.detail.acArticles != undefined) {
    const response = await api.saveShipmentDetailAcArticle(
      props.detail.id,
      article,
    );
    if (index !== null && index !== undefined) {
      props.detail.acArticles.unshift({ ...response.data });
    } else {
      props.detail.acArticles[index] = response.data;
      timeAcArticlesStampKey.value = Date.now();
    }
    acArticleDialog.value = false;
  }
  selectedAcArticle.value = defaultArticle;
  selectedIndex.value = null;
};
const closeAcArticleDialog = () => {
  acArticleDialog.value = false;
  selectedIndex.value = null;
  selectedAcArticle.value = defaultArticle;
};
</script>
